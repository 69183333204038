@use "sass:math";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "font.scss";

$primary-background-color: #000000;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: $primary-background-color;
  color: #ffffff;
  font-size: 16px;
}

.border-radius-2rem {
  border-radius: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.login-title-dropdown {
  &::after {
    display: none;
  }
}

.login-title-dropdown1 {
  &::after {
    display: none;
  }
}

@for $i from 0 through 100 {
  .w-#{$i} {
    width: percentage(math.div($i, 100)) !important;
  }
  .w-#{$i}px {
    width: #{$i}px !important;
  }
  .w-max-#{$i}px {
    max-width: #{$i}px !important;
  }
  .h-min-#{$i}vh {
    min-height: #{$i}vh !important;
  }
  .h-#{$i}vh {
    height: #{$i}vh !important;
  }
  .h-#{$i}px {
    height: #{$i}px !important;
  }
  .h-min-#{$i}px {
    min-height: #{$i}px !important;
  }
  .h-#{$i} {
    height: percentage(math.div($i, 100)) !important;
  }
  .h-max-#{$i} {
    max-height: percentage(math.div($i, 100)) !important;
  }
  .mt-#{$i}perc {
    margin-top: percentage(math.div($i, 100)) !important;
  }
  .mb-#{$i}perc {
    margin-bottom: percentage(math.div($i, 100)) !important;
  }
  .ms-#{$i}perc {
    margin-left: percentage(math.div($i, 100)) !important;
  }
  .me-#{$i}perc {
    margin-right: percentage(math.div($i, 100)) !important;
  }
  .ps-#{$i}px {
    padding-left: #{$i}px !important;
  }
  .font-size-#{$i}px {
    font-size: #{math.div($i, 16)}rem !important;
  }
  .gap-#{$i}px {
    gap: #{$i}px;
  }
  .mt-#{$i}perc {
    margin-top: percentage(math.div($i, 100)) !important;
  }
  .position-right-#{$i}px {
    right: #{$i}px;
  }
  .position-left-#{$i}px {
    left: #{$i}px;
  }
  .position-left-#{$i}perc {
    left: percentage(math.div($i, 100)) !important;
  }
  .position-bottom-#{$i}px {
    bottom: #{$i}px !important;
  }
  .position-top-#{$i}px {
    top: #{$i}px;
  }
  .position-right-#{$i}perc {
    right: percentage(math.div($i, 100));
  }
  .position-top-#{$i}perc {
    top: percentage(math.div($i, 100));
  }
  .vh-#{$i} {
    height: #{$i}vh;
  }
  .border-radius-#{$i}px {
    border-radius: #{$i}px;
  }
  .line-height-#{$i}px {
    line-height: #{$i}px !important;
  }
  .font-weight-#{$i} {
    font-weight: #{$i} !important;
  }
  .z-index-#{$i} {
    z-index: #{$i} !important;
  }
}

@for $i from 101 through 10000 {
  .position-top-#{$i}px {
    top: #{$i}px;
  }
  .h-#{$i}px {
    height: #{$i}px;
  }
  .h-min-#{$i}px {
    min-height: #{$i}px !important;
  }
  .w-max-#{$i}px {
    max-width: #{$i}px !important;
  }
  .position-left-#{$i}px {
    left: #{$i}px;
  }
  .position-bottom-#{$i}px {
    bottom: #{$i}px !important;
  }
  .line-height-#{$i}px {
    line-height: #{$i}px !important;
  }
  .font-weight-#{$i} {
    font-weight: #{$i} !important;
  }
  .z-index-#{$i} {
    z-index: #{$i} !important;
  }
}

.bg-fluorescent-blue {
  background-color: #15f4ee !important;
}

.btn-fluorescent-blue {
  background-color: #15f4ee !important;
  color: #000000 !important;
  &:hover {
    color: #000000 !important;
    background-color: #15f4ee !important;
  }
}

.text-fluorescent-blue {
  color: #15f4ee !important;
}

.bg-yinmn-blue {
  background-color: #225288 !important;
}

.btn-yinmn-blue {
  background-color: #225288 !important;
  color: #ffffff !important;
  &:hover {
    color: #ffffff !important;
    background-color: #225288 !important;
  }
}

.text-yinmn-blue {
  color: #225288 !important;
}

.bg-charleston-green {
  background-color: #2a2b2f !important;
}

.btn-charleston-green {
  background-color: #2a2b2f !important;
  color: #ffffff !important;
  &:hover {
    color: #ffffff !important;
    background-color: #2a2b2f !important;
  }
}

.text-charleston-green {
  color: #2a2b2f !important;
}

.bg-black {
  background-color: #000000 !important;
}

.text-black {
  color: #000000 !important;
}

.text-black-hover {
  &:hover {
    color: #000000 !important;
  }
}

.text-color-232323 {
  color: #232323 !important;
}

.btn-white {
  background-color: #ffffff !important;
  color: #000000 !important;
  &:hover {
    color: #000000 !important;
    background-color: #ffffff !important;
  }
}

.btn-no-color {
  background-color: transparent !important;
  border: none !important;
  &:hover {
    background-color: transparent !important;
    border: none !important;
  }
  &:focus {
    background-color: transparent !important;
    border: none !important;
  }
  &:focus-visible {
    background-color: transparent !important;
    border: none !important;
  }
  &:active {
    background-color: transparent !important;
    border: none !important;
  }
}

.odin-checkbox {
  position: relative;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 10px;
}

.odin-checkbox input {
  position: absolute;
  width: 0;
  left: 50px;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.odin-checkbox .checkmark {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 3px;
  outline: 1px solid #000000;
  transition: all 0.2s ease;
}

.odin-checkbox:hover .checkmark {
  background: #000000;
  transition: all 0.2s ease;
}

.odin-checkbox input:checked ~ .checkmark {
  background: #000000;
  outline: 1px solid #000000;
}

.odin-checkbox input[type="radio"] ~ .checkmark {
  border-radius: 50%;
}

.odin-checkbox .checkmark::after {
  position: absolute;
  display: block;
  content: "";
  left: 50%;
  top: 40%;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: all 0.2s ease;
}

.odin-checkbox input:checked ~ .checkmark::after {
  opacity: 1;
  transition: all 0.2s ease;
}

.search-box1 {
  bottom: 35px;
  left: 15px;
  float: right;
}

.search-box2 {
  bottom: 35px;
  left: 34px;
  float: right;
}

.search-box3 {
  bottom: 35px;
  left: 24px;
  float: right;
}

.bg-EAEAEA {
  background-color: #eaeaea !important;
}

.text-color-474747 {
  color: #474747 !important;
}

.border-color-CBCBCB {
  border-color: #cbcbcb !important;
}

.border-radius-tl0px-tr12px-br12px-bl12px {
  border-radius: 0 12px 12px 12px;
}

.border-radius-tl12px-tr0px-br12px-bl12px {
  border-radius: 12px 0 12px 12px;
}

.bg-E0E9EE {
  background-color: #e0e9ee !important;
}

.bg-CBCBCB {
  background-color: #cbcbcb !important;
}

.bg-transperant {
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.border-color-C0C0C0 {
  border-color: #c0c0c0 !important;
}

.btn-transparent {
  border: none;
  &:focus-visible {
    border: none;
  }
  &:active {
    border: none;
  }
}

.btn-info {
  color: #000000 !important;
}

.search-txt-main-div1 {
  .dropdown-menu.show {
    max-width: 320px;
  }
}

@keyframes odin-img-rotate1 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.rotate-odin-image1 {
  animation: odin-img-rotate1 0.8s reverse infinite;
}

@include media-breakpoint-down(sm) {
  @for $i from 0 through 100 {
    .order-sm-#{$i} {
      order: #{$i};
      -webkit-order: #{$i};
    }
    .mt-sm-#{$i} {
      margin-top: #{math.div($i, 16)}rem !important;
    }
    .me-sm-#{$i} {
      margin-right: #{math.div($i, 16)}rem !important;
    }
    .ms-sm-#{$i} {
      margin-left: #{math.div($i, 16)}rem !important;
    }
    .position-right-sm-#{$i}perc {
      right: percentage(math.div($i, 100));
    }
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .row-sm {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
}

@include media-breakpoint-between(sm, md) {
  @for $i from 0 through 100 {
    .order-md-#{$i} {
      order: #{$i};
      -webkit-order: #{$i};
    }
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
}

@include media-breakpoint-up(sm) {
  @for $i from 0 through 500 {
    .postion-bottom-md-#{$i} {
      bottom: #{math.div($i, 16)}rem !important;
    }
    .postion-bottom-md-#{$i}px {
      bottom: #{$i}px !important;
    }
  }
}

.error-div-bg {
  background-color: rgba(255, 124, 128, 0.5) !important;
}

.flag-dropdown {
  border: none !important;
}

.react-tel-input .flag-dropdown {
  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius) !important;
}

.text-justify {
  text-align: justify !important;
}

.border-color-757070 {
  border-color: #757070 !important;
}

.flex-1 {
  flex: 1;
}

.btn-white {
  background-color: #ffffff;
  &:hover {
    color: #000000 !important;
    background-color: #ffffff !important;
  }
}

.btn-7f7f7f {
  background-color: #7f7f7f !important;
  color: #ffffff !important;
  &:hover {
    color: #ffffff !important;
    background-color: #7f7f7f !important;
  }
}

.border-7f7f7f {
  border-color: #7f7f7f !important;
}

.text-color-757070 {
  color: #757070 !important;
}
.contents-wrap {
  max-width: 325px;
  width: 100%;
  transition: all ease 1.25s;
}
.contents-wrap-welcome {
  max-width: 250px;
  width: 100%;
  transition: all ease 1.25s;
}
.initial-box {
  display: flex;
  align-items: center;
  border-radius: 15px;
}
.initial-box .icons img {
  width: 38px;
}
.initial-box .icons {
  margin-right: 7px;
}
.cardbox .card {
  border-radius: 15px;
}
.top-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-icon img {
  width: 35px;
}

.top-icon1 {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-icon1 img {
  width: 35px;
}

.login-title-dropdown {
  background: #f4b081;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-title-dropdown1 {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.library-box {
  width: 335px;
}
.library-head {
  position: relative;
  border-bottom: solid 1px #000;
  padding-bottom: 10px;
}
.library-head .icon img {
  width: 32px;
  margin-right: 5px;
}
.close-lb {
  position: absolute;
  right: 11px;
}
.source-imgs {
  width: 70px;
  margin-right: 10px;
  flex: 0 0 70px;
}
.source-imgs img {
  height: 65px;
  width: 100%;
  object-fit: cover;
}
.tabitem {
  border-bottom: solid 1px #7f7f7f;
  color: #225288;
  font-size: 13px;
}
.search-contents-box {
  max-width: 720px;
  width: 100%;
  height: calc(100vh - 8px);
}
.searchcontents {
  height: calc(100vh - 106px);
}
.text-content {
  max-width: 700px;
  width: 100%;
  font-size: 12px;
}
.ans-icon img {
  width: 40px;
}

.search-btn-toggle1 {
  &::after {
    content: none !important;
  }
}

/**************** custom scrollbar started *******************/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #414141;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/**************** custom scrollbar ended *******************/
.text-compose {
  max-width: 780px;
  margin: auto;
}
.quest-box {
  max-width: 320px;
  margin: auto;
  font-size: 13px;
}

/* Medium devices (tablets, 767px to 992px) */
@media (min-width: 768px) and (max-width: 992px) {
  .search-contents-box {
    max-width: 274px;
  }
  .library-box {
    width: 242px;
  }
  @for $i from 0 through 500 {
    .w-max-tablet-#{$i}px {
      max-width: #{$i}px !important;
    }
    .position-right-tablet-#{$i}perc {
      right: percentage(math.div($i, 100));
    }
  }
}

/* Large devices (desktops, 992px to 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .search-contents-box {
    max-width: 522px;
  }
  .library-box {
    width: 244px;
  }
  @for $i from 0 through 100 {
    .w-max-desktop-#{$i}px {
      max-width: #{$i}px !important;
    }
    .position-right-desktop-#{$i}perc {
      right: percentage(math.div($i, 100));
    }
  }
}
